import Container from './styles';

const Input = ({ label, classNameContainer = '', htmlFor = '', ...rest }) => (
  <Container className={classNameContainer}>
    <label htmlFor={htmlFor}>{label}</label>
    <input {...rest} />
  </Container>
);

export default Input;
