import PropTypes from 'prop-types';

import Button from './styles';

const LinkItem = ({ url, description, disabled }) =>
  !disabled ? (
    <a href={url} target="_blank" rel="noreferrer">
      <Button>
        <p>{description}</p>
      </Button>
    </a>
  ) : (
    <Button disabled>
      <p>{description} (Em Breve)</p>
    </Button>
  );

LinkItem.propTypes = {
  url: PropTypes.string,
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

LinkItem.defaultProps = {
  url: '',
  disabled: false,
};

export default LinkItem;
