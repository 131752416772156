import { gql } from '@apollo/client';

export const LINKS = gql`
  query GetLinks {
    links(sort: "order") {
      description
      url
      order
      disabled
    }
  }
`;

export const POSTS = gql`
  query getPosts($limit: Int) {
    posts(sort: "published_at", limit: $limit) {
      slug
      id
      content
      cover {
        id
        url
        alternativeText
      }
      title
      published_at
      labels {
        name
      }
      writer {
        id
        name
        profile {
          alternativeText
          url
        }
      }
    }
  }
`;

export const POST = gql`
  query getPost($id: ID!) {
    post(id: $id) {
      slug
      id
      content
      cover {
        id
        url
        alternativeText
      }
      writer {
        id
        name
        profile {
          alternativeText
          url
        }
      }
      title
      published_at
    }
  }
`;
export const GET_POST_BY_SLUG = gql`
  query getPost($slug: String!) {
    postBySlug(slug: $slug) {
      slug
      id
      content
      cover {
        id
        url
        alternativeText
      }
      writer {
        id
        name
        profile {
          alternativeText
          url
        }
      }
      title
      published_at
    }
  }
`;

export const TOOLS = gql`
  query getTools {
    tools {
      id
      title
      icon {
        url
        alternativeText
      }
      description
      link
      screenshots {
        id
        url
        alternativeText
      }
    }
  }
`;
