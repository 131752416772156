import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Routes from 'routes/routes';
import Context from 'services/Context';
import { ThemeProvider } from 'styled-components';
import light from 'theme/light';

import GlobalStyles from './theme/globalStyles';

const client = new ApolloClient({
  uri: process.env.REACT_APP_URL,
  cache: new InMemoryCache(),
});

function App() {
  return (
    <Context>
      <ApolloProvider client={client}>
        <ThemeProvider theme={light}>
          <GlobalStyles />
          <Routes />
        </ThemeProvider>
      </ApolloProvider>
    </Context>
  );
}

export default App;
