import styled from 'styled-components';

export default styled.button`
  max-width: 320px;
  width: 100%;
  margin-bottom: 4px;
  border: none;
  background-image: url('/static/media/blockBackground.57c41e105b717ede54d7.png');
  border-bottom: 4px solid rgb(222, 222, 222);
  padding: 8px;

  &:not([disabled]):hover {
    cursor: pointer;
    transition: all 0.2s;
    border-color: rgba(0, 0, 0, 0.5);
    background-color: ${({ theme }) => theme.button.primary};
    color: white;
  }
`;
