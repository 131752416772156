import styled from 'styled-components';

export default styled.button`
  width: 100%;
  height: 4rem;
  background: ${({ theme }) => theme.button.primary};
  border: none;
  color: #ffffff;
  font-size: 1.12rem;
  font-weight: 700;
  cursor: pointer;
`;
