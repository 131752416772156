import { useMutation, useQuery } from '@apollo/client';
import calendarIcon from 'assets/calendarIcon.svg';
import calendarWhiteIcon from 'assets/calendarWhiteIcon.svg';
import cameraIcon from 'assets/cameraWhite.svg';
import documentIcon from 'assets/documentIcon.svg';
import documentWhiteIcon from 'assets/documentWhiteIcon.svg';
import linkIcon from 'assets/linkIcon.png';
import phoneIcon from 'assets/phoneIcon.svg';
import phoneWhiteIcon from 'assets/phoneWhiteIcon.svg';
import profile from 'assets/profile.png';
import Main from 'components/Containers/Main';
import CopySucess from 'components/CopySucess';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import TextArea from 'components/Form/Textarea';
import PageLayout from 'components/PageLayout';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { useFormik } from 'formik';
import Carousel from 'nuka-carousel';
import { useEffect, useState, useRef } from 'react';
import {
  AiOutlineLink,
  AiOutlineLoading,
  AiFillCheckSquare,
} from 'react-icons/ai';
import { FaInstagram } from 'react-icons/fa';
import { HiPlusSm } from 'react-icons/hi';
import { ImLinkedin2, ImFacebook, ImTwitter } from 'react-icons/im';
import {
  IoLogoLinkedin,
  IoIosArrowBack,
  IoIosArrowForward,
} from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useContextProvider } from 'services/Context';
import { SEND_MESSAGE } from 'services/graphql/mutations';
import { POSTS } from 'services/graphql/queries';
import sendMessageSchema from 'services/yupSchemas/sendMessage';

import Container, {
  ButtonCarrousel,
  ImageContainer,
  ContainerComment,
  Block,
  TextContainer,
  SocialRedesContainer,
  SocialRedesContainerMobile,
} from './styles';

const initialValues = {
  email: '',
  name: '',
  message: '',
};

const Home = () => {
  const { updateToPrimary } = useContextProvider();
  const [loading, setLoading] = useState(false);
  const [send, setSend] = useState(false);
  const [sendMessage] = useMutation(SEND_MESSAGE);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const carrouselRef = useRef();
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { resetForm }) => {
      setSend(false);
      if (loading) {
        return;
      }
      setLoading(true);
      try {
        await sendMessage({
          variables: {
            name: values.name,
            email: values.email,
            message: values.message,
          },
        });
        setLoading(false);
        setSend(true);
        resetForm();
      } catch (e) {
        setLoading(false);
      }
    },
    validationSchema: sendMessageSchema,
  });

  useEffect(() => {
    if (send) {
      setTimeout(() => {
        setSend(false);
      }, [10000]);
    }
  }, [send]);

  const { data } = useQuery(POSTS, {
    variables: {
      limit: 3,
    },
  });

  const [indexCarrousel, setIndexCarrousel] = useState({
    index: 0,
    title: '',
  });
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    document.title = 'Home';
    updateToPrimary();
  }, [updateToPrimary]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const newPosts = data.posts.map(post => {
      const dateFormat = format(
        parseISO(post.published_at),
        "dd 'de' MMMM - yyyy",
        {
          locale: ptBR,
        }
      );
      const urlWriteComplete = `${process.env.REACT_APP_URL_IMAGE}${post.writer.profile.url}`;
      const resume = post.content.split('\n')[0];
      return {
        ...post,
        dateFormat,
        resume,
        urlComplete: `${process.env.REACT_APP_URL_IMAGE}${post.cover.url}`,
        writer: {
          ...post.writer,
          profile: { ...post.writer.profile, url: urlWriteComplete },
        },
      };
    });
    setPosts(newPosts);
    setIndexCarrousel({
      index: 0,
      title: newPosts[0].slug,
    });
  }, [data]);

  function afterSlide(currentSlide) {
    if (currentSlide === indexCarrousel.index) {
      return;
    }
    setIndexCarrousel({
      index: currentSlide,
      title: posts[currentSlide].slug,
    });
  }

  return (
    <PageLayout>
      <Container key="container">
        <CopySucess text="Link Copiado" show={showCopyMessage} />
        <Main className="zIndex" id="home">
          <TextContainer id="about">
            <h2>Olá,</h2>
            <h1>Bem-vindo!</h1>
            <p>
              Discípulo de Jesus Cristo, programador criativo com mais de 10
              anos de experiência, fundador da empresa ORANGO I/O TECNOLOGIA,
              concluindo o curso de medicina na Universidade Federal da Bahia.{' '}
            </p>
            <Link to="/sobre-mim">
              <HiPlusSm size={24} color="#5E5E5E" />
              <p> Sobre mim</p>
            </Link>
          </TextContainer>
          <ImageContainer>
            <img src={profile} alt="Filipe" />
            <div>
              <span className="square" />
              <span className="square" />
              <span className="square" />
            </div>
            <div>
              <div>
                <span className="smallSquare" />
              </div>
              <span className="larger_background" />
            </div>
          </ImageContainer>
          <Block>
            <Link to="/links-interessantes">
              <div>
                <img src={documentIcon} alt="documento" />
                <img src={documentWhiteIcon} alt="documento" />
              </div>
              <p>Link interessantes</p>
              <span>
                <img src={linkIcon} alt="link" />
              </span>
            </Link>
          </Block>
          <Block>
            <Link to="ferramentas">
              <div>
                <img src={calendarIcon} alt="calendário" />
                <img src={calendarWhiteIcon} alt="calendário" />
              </div>
              <p>Ferramentas médicas</p>
              <span>
                <img src={linkIcon} alt="link" />
              </span>
            </Link>
          </Block>
          <Block disabled>
            <Link to="/">
              <div className="alert">Em Breve</div>
              <div>
                <img src={phoneIcon} alt="telefone" />
                <img src={phoneWhiteIcon} alt="telefone" />
              </div>
              <p>Agende sua consulta</p>
              <span>
                <img src={linkIcon} alt="link" />
              </span>
            </Link>
          </Block>
          <div className="text_tecnolog">
            <h2>{indexCarrousel.title}</h2>
          </div>
          <SocialRedesContainer>
            <button
              type="button"
              onClick={() => {
                if (
                  navigator.canShare &&
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                ) {
                  navigator.share({
                    text: `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`,
                  });
                } else {
                  const url = window.encodeURI(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel?.slug}`
                  );
                  window.open(`https://www.facebook.com/sharer.php?u=${url}`);
                }
              }}
              aria-label="link para o facebook"
            >
              <ImFacebook color="#fff" size={24} />
            </button>
            <button
              type="button"
              onClick={() => {
                if (
                  navigator.canShare &&
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                ) {
                  navigator.share({
                    text: `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`,
                  });
                } else {
                  const url = window.encodeURI(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel?.title}`
                  );
                  window.open(
                    `https://www.linkedin.com/shareArticle/?url=${url}`
                  );
                }
              }}
              aria-label="link para o linkedin"
            >
              <ImLinkedin2 color="#fff" size={24} />
            </button>
            <button
              type="button"
              onClick={() => {
                if (
                  navigator.canShare &&
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                ) {
                  navigator.share({
                    text: `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`,
                  });
                } else {
                  const url = window.encodeURI(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel?.title}`
                  );
                  window.open(`https://twitter.com/share?url=${url}`);
                }
              }}
              aria-label="link para o twitter"
            >
              <ImTwitter color="#fff" size={24} />
            </button>
            <button
              onClick={() => {
                if (navigator.canShare) {
                  navigator.share({
                    text: `http://localhost:3000/${indexCarrousel.title}`,
                  });
                }
              }}
              type="button"
              aria-label="link para o instagram"
            >
              <img src={cameraIcon} alt="instagram" />
            </button>
            <button
              type="button"
              aria-label="copiar link"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`
                  )
                  .then(() => {
                    setShowCopyMessage(true);
                    setTimeout(() => {
                      setShowCopyMessage(false);
                    }, 1000);
                  });
              }}
            >
              <AiOutlineLink color="#fff" size={24} />
            </button>
          </SocialRedesContainer>
        </Main>
        <div className="full-container">
          <Carousel
            ref={carrouselRef}
            slideIndex={indexCarrousel.index}
            afterSlide={currentSlide => afterSlide(currentSlide)}
            renderCenterLeftControls={({ previousSlide }) => (
              <ButtonCarrousel
                className="left"
                type="button"
                onClick={previousSlide}
                aria-label="slide anterior"
              >
                <span />
                <IoIosArrowBack size={40} color="#fff" />
              </ButtonCarrousel>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <ButtonCarrousel
                className="right"
                type="button"
                onClick={nextSlide}
                aria-label="próximo slide"
              >
                <span />
                <IoIosArrowForward size={40} color="#fff" />
              </ButtonCarrousel>
            )}
            withoutControls
            defaultControlsConfig={{
              pagingDotsContainerClassName: 'dot',
            }}
          >
            {posts.map(post => (
              <img key={post.id} src={post.urlComplete} alt="test" />
            ))}
          </Carousel>
        </div>
        <Main className="top">
          <ContainerComment>
            <Carousel
              autoplay
              autoplayInterval={10000}
              wrapAround
              slideIndex={indexCarrousel.index}
              afterSlide={currentSlide => afterSlide(currentSlide)}
              renderCenterLeftControls={({ previousSlide }) => (
                <ButtonCarrousel
                  className="left"
                  type="button"
                  onClick={previousSlide}
                  aria-label="slide anterior"
                >
                  <IoIosArrowBack size={40} color="#fff" />
                  <span />
                </ButtonCarrousel>
              )}
              renderCenterRightControls={({ nextSlide }) => (
                <ButtonCarrousel
                  className="right"
                  type="button"
                  onClick={nextSlide}
                  aria-label="próximo slide"
                >
                  <IoIosArrowForward size={40} color="#fff" />
                  <span />
                </ButtonCarrousel>
              )}
              defaultControlsConfig={{
                pagingDotsContainerClassName: 'dot',
              }}
            >
              {posts.map(post => (
                <div className="comment" key={post.id}>
                  <p>{post.content}</p>
                  <div className="profile-container">
                    <img
                      src={post.writer.profile.url}
                      alt={post.writer.profile.alternativeText}
                    />
                    <p>
                      {post.dateFormat},
                      <strong>{`port ${post.writer.name}`}</strong>
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </ContainerComment>
          <SocialRedesContainerMobile>
            <button
              type="button"
              onClick={() => {
                if (
                  navigator.canShare &&
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                ) {
                  navigator.share({
                    text: `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`,
                  });
                } else {
                  const url = window.encodeURI(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel?.title}`
                  );
                  window.open(`https://www.facebook.com/sharer.php?u=${url}`);
                }
              }}
              aria-label="link para o facebook"
            >
              <ImFacebook color="#fff" size={12} />
            </button>
            <button
              type="button"
              onClick={() => {
                if (
                  navigator.canShare &&
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                ) {
                  navigator.share({
                    text: `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`,
                  });
                } else {
                  const url = window.encodeURI(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel?.title}`
                  );
                  window.open(
                    `https://www.linkedin.com/shareArticle/?url=${url}`
                  );
                }
              }}
              aria-label="link para o linkedin"
            >
              <ImLinkedin2 color="#fff" size={12} />
            </button>
            <button
              type="button"
              onClick={() => {
                if (
                  navigator.canShare &&
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                ) {
                  navigator.share({
                    text: `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`,
                  });
                } else {
                  const url = window.encodeURI(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel?.title}`
                  );
                  window.open(`https://twitter.com/share?url=${url}`);
                }
              }}
              aria-label="link para o twitter"
            >
              <ImTwitter color="#fff" size={12} />
            </button>
            <button
              onClick={() => {
                if (navigator.canShare) {
                  navigator.share({
                    text: `http://localhost:3000/${indexCarrousel.title}`,
                  });
                }
              }}
              type="button"
              aria-label="link para o instagram"
            >
              <img src={cameraIcon} alt="instagram" />
            </button>
            <button
              type="button"
              aria-label="copiar link"
              onClick={() => {
                navigator.clipboard
                  .writeText(
                    `${process.env.REACT_APP_URL_FRONT}/${indexCarrousel.title}`
                  )
                  .then(() => {
                    setShowCopyMessage(true);
                    setTimeout(() => {
                      setShowCopyMessage(false);
                    }, 1000);
                  });
              }}
            >
              <AiOutlineLink color="#fff" size={12} />
            </button>
          </SocialRedesContainerMobile>
          <div className="footer-social-redes">
            <h3>Minhas Redes</h3>
            <div>
              <a
                href="https://www.instagram.com/filipelopes.med.br"
                aria-label="link para o instagram"
              >
                <FaInstagram size={48} color="#74AB82" />
              </a>
              <a
                href="https://www.linkedin.com/in/filipe-rocha-lopes-751478174"
                aria-label="link para o linkedin"
              >
                <IoLogoLinkedin size={48} color="#74AB82" />
              </a>
            </div>
          </div>
          <form
            className="footer-form"
            id="contato"
            onSubmit={formik.handleSubmit}
          >
            <Input
              htmlFor="name"
              id="name"
              arial-label="nome"
              label="Nome"
              classNameContainer="input"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            <Input
              htmlFor="email"
              id="email"
              arial-label="email"
              type="email"
              label="E-mail"
              classNameContainer="input"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <TextArea
              htmlFor="message"
              id="message"
              arial-label="mensagem"
              label="Mensagem"
              classNameContainer="textarea"
              name="message"
              onChange={formik.handleChange}
              value={formik.values.message}
            />
            <Button type="submit">
              {loading && (
                <AiOutlineLoading className="loading" size={45} color="#fff" />
              )}
              {!loading && !send && 'Enviar'}
              {send && <AiFillCheckSquare size={45} color="#fff" />}
            </Button>
          </form>
        </Main>
      </Container>
    </PageLayout>
  );
};

export default Home;
