import logo from 'assets/logo.png';
import Main from 'components/Containers/Main';
import { useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import Container, { MobileMenu } from './styles';

const Header = ({ secondary = false }) => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <Container secondary={secondary} openMenu={openMenu}>
      <span className="block-big" />
      <span className="block-larger white" />
      <span className="block-larger" />
      <span className="block-small" />
      <Main>
        <div>
          <button
            type="button"
            className="burguer_menu"
            aria-label="menu"
            onClick={() => setOpenMenu(true)}
          >
            <span />
            <span />
          </button>
          <Link to="/">
            <img src={logo} alt="logo" className="logo-image" />
          </Link>
          <span className="tag-space" />
          <nav>
            <ul>
              <li>
                {location.pathname === '/' && !location.hash && <span />}
                <Link to="/">Home</Link>
              </li>
              <li>
                {location.pathname === '/sobre-mim' && <span />}
                <Link to="/sobre-mim">Sobre Mim</Link>
              </li>
              <li>
                {location.pathname === '/projetos' && <span />}
                <Link to="/projetos">Projetos</Link>
              </li>
              <li>
                {location.hash === '#contato' && <span />}
                <HashLink to="/#contato">Contato</HashLink>
              </li>
            </ul>
          </nav>
          <button
            className="background-mobile_menu"
            type="button"
            aria-label="fechar menu"
            onClick={() => setOpenMenu(false)}
          />
          <MobileMenu openMenu={openMenu}>
            <nav>
              <div>
                <p>{location.pathname.split('/')[1].toUpperCase() || 'HOME'}</p>
                <button
                  type="button"
                  onClick={() => setOpenMenu(false)}
                  aria-label="fechar menu"
                >
                  <BsArrowLeft color="#fff" size={24} />
                </button>
              </div>
              <ul>
                <li>
                  <Link to="/" onClick={() => setOpenMenu(false)}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/sobre-mim" onClick={() => setOpenMenu(false)}>
                    Sobre Mim
                  </Link>
                </li>
                <li>
                  <Link to="/projetos" onClick={() => setOpenMenu(false)}>
                    Projetos
                  </Link>
                </li>
                <li>
                  <HashLink to="/#contato" onClick={() => setOpenMenu(false)}>
                    Contato
                  </HashLink>
                </li>
              </ul>
            </nav>
          </MobileMenu>
        </div>
      </Main>
    </Container>
  );
};

export default Header;
