import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: #fafafa;
  margin-top: 12rem;

  .header {
    padding-bottom: 2rem;
  }

  .margin {
    margin-bottom: 2rem;
  }

  a {
    text-decoration: none;
  }

  .title {
    grid-column: span 12;
    font-size: 4.5rem;
    color: #74ab82;
    text-align: center;
    margin-bottom: 7.5rem;
  }

  .card-highlight {
    grid-column: span 8;
  }
  .card-medium {
    grid-column: span 4;

    > div {
      margin-bottom: 2.5rem;
    }
  }

  .card-normal {
    grid-column: span 3;
  }

  .card-big {
    margin-bottom: 11.25rem;
    margin-top: 3.75rem;
    z-index: 1;

    > div {
      grid-column: span 4;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-weight: 600;
        font-size: 2.25rem;
        color: ${({ theme }) => theme.text.primary};
      }

      p {
        font-weight: 500;
        font-size: 1.125rem;
        color: #1c1c1c;
        margin-top: 2rem;
      }
    }

    > img {
      grid-column: span 8;
      height: 35rem;
      width: 100%;
      object-fit: cover;
    }
  }

  > div {
    z-index: 2;
  }

  .header-article {
    grid-column-start: 2;
    grid-column-end: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-weight: 600;
      color: ${({ theme }) => theme.text.primary};
      font-size: 2.25rem;
    }

    > div {
      align-self: stretch;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .social_redes_container {
        display: flex;

        a {
          cursor: pointer;
          width: 1.5rem;
          height: 1.5rem;
          background: #5e5e5e;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin-right: 0.8rem;
        }

        img {
          width: 12px;
          object-fit: contain;
        }
      }
    }
  }

  .content-article {
    grid-column-start: 3;
    grid-column-end: 11;
    margin-top: 2rem;
    margin-bottom: 7rem;

    p {
      font-size: 1.12rem;
      color: ${({ theme }) => theme.text.primary};
      margin-bottom: 2.25rem;
    }

    img {
      width: 100%;
      height: 15rem;
      object-fit: cover;
      margin-bottom: 1.5rem;
    }

    h4 {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.text.primary};
      font-weight: 600;
      text-align: center;
    }
  }
  .most-views {
    width: 100%;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 7rem;
    z-index: 1;

    h2 {
      grid-column: span 12;
      font-weight: 600;
      font-size: 2.25rem;
      color: #3e3f3d;
      margin-top: 6rem;
      margin-bottom: 3.4rem;
    }
  }

  .full-container {
    width: 100%;
    height: 50rem;

    img {
      width: 100%;
      height: 50rem;
      object-fit: cover;
    }
  }

  .profile-container {
    margin-top: 1.3rem;
    display: flex;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      margin-right: 1rem;
    }

    p {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.text.primary};
    }
  }
`;
