import Container from './styles';

const TextArea = ({ label, classNameContainer = '', htmlFor, ...rest }) => (
  <Container className={classNameContainer}>
    <label htmlFor={htmlFor}>{label}</label>
    <textarea {...rest} />
  </Container>
);

export default TextArea;
