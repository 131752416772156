import styled from 'styled-components';

export default styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  grid-column: span 13;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  background: ${({ secondary }) => (secondary ? '#FAFAFA' : '#fff')};
  height: 8rem;

  .tag-space {
    display: none;
  }

  .block-larger {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 5.62rem;
    height: 5.62rem;
    background: #f2f2f2;

    &.white {
      top: 0;
      background: #fff;
    }
  }

  .block-big {
    position: absolute;
    top: 0rem;
    right: 0;
    width: 11rem;
    height: 100%;
    background: #fff;
  }

  .block-small {
    bottom: -2.81rem;
    left: 5.62rem;
    position: absolute;
    width: 2.81rem;
    height: 2.81rem;
    background: #f2f2f2;
  }

  > div {
    z-index: 2;
    > div {
      grid-column: span 13;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .logo-image {
        width: 5.5rem;
        height: 5.5rem;
      }

      > nav {
        width: 73%;

        ul {
          display: flex;
          list-style: none;
          justify-content: space-between;
          font-size: 1.12rem;

          li {
            display: flex;
            align-items: center;
          }

          span {
            width: 0.4rem;
            height: 0.4rem;
            background: #74ab82;
            display: block;
            margin-right: 0.5rem;
          }
          a {
            color: ${({ theme }) => theme.text.primary};
            text-decoration: none;
          }
        }
      }

      .background-mobile_menu {
        display: none;
        background: none;
        border: none;
        outline: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.3);
      }

      .burguer_menu {
        display: none;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        align-self: baseline;
        padding-top: 2rem;

        > span {
          display: block;
          height: 3.69px;
          background: #74ab82;

          :first-child {
            width: 3.75rem;
            margin-bottom: 4px;
          }

          :last-child {
            width: 1.87rem;
          }
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .block-larger {
      width: 2rem;
      height: 2rem;
      right: 1rem;
      top: 1rem;
      left: auto;

      &.white {
        display: none;
      }
    }

    .block-big {
      width: 5rem;
    }

    .block-small {
      width: 1rem;
      height: 1rem;
      right: 0;
      top: 0;
      left: auto;
    }
  }

  @media (max-width: 800px) {
    min-height: 8rem;
    margin: 0;
    width: 100%;
    .tag-space {
      display: block;
      width: 60px;
    }
    .block-big {
      display: none;
    }

    > div > div {
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;

      .logo-image {
        width: 5rem;
        height: 5rem;
      }

      > nav {
        display: none;
      }
      .burguer_menu {
        display: block;
      }
      .background-mobile_menu {
        display: ${({ openMenu }) => (openMenu ? 'flex' : 'none')};
      }
    }
  }
`;

export const MobileMenu = styled.div`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 87vw;
  height: 100vh;
  z-index: 2;
  background: #77cc90;
  transition: transform 0.2s linear;
  transform: translateX(-100%);

  nav {
    display: flex;
    flex: 1;
    align-self: stretch;
    flex-direction: column;
    color: #fff;
    align-items: center;

    div {
      align-self: stretch;
      height: 4.37rem;
      background: #74ab82;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;

      p {
        font-size: 1.125rem;
      }

      button {
        cursor: pointer;
        border: none;
        background: none;
        cursor: pointer;
      }
    }

    > ul {
      display: flex;
      flex: 1;
      justify-content: center;
      flex-direction: column;
      list-style: none;
      li,
      a {
        color: #fff;
        text-decoration: none;
        font-size: 2.25rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }
    }
  }

  @media (max-width: 800px) {
    transform: ${({ openMenu }) =>
      openMenu ? 'translateX(0%)' : 'translateX(-100%)'};
  }
`;
