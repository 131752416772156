import styled from 'styled-components';

export default styled.div`
  width: 100%;

  label {
    font-size: 1.12rem;
    color: ${({ theme }) => theme.text.primary};
    margin-bottom: 0.5rem;
  }

  textarea {
    width: 100%;
    height: 10.5rem;
    background: #ebebeb;
    border: none;
    resize: none;
    padding-left: 1rem;
    padding-top: 1rem;
  }
`;
