import { gql } from '@apollo/client';

export const SEND_MESSAGE = gql`
  mutation sendMessage($name: String, $email: String, $message: String) {
    createMessage(
      input: { data: { name: $name, email: $email, message: $message } }
    ) {
      message {
        id
      }
    }
  }
`;
