import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export default styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-decoration: none;

  img,
  .image_container {
    height: 35rem;
    object-fit: cover;
    width: 100%;
  }

  h3 {
    font-size: 2.25rem;
    color: ${({ theme }) => theme.text.primary};
    font-weight: 600;
  }

  p {
    color: ${({ theme }) => theme.text.primary};
    font-weight: 500;
    font-size: 1.12rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  ${({ sizetype }) => {
    switch (sizetype) {
      case 'larger':
        return css`
          img,
          .image_container {
            height: 35.12rem;
          }
          h3 {
            font-size: 1.5rem;
            margin-top: 2rem;
            margin-bottom: 1.5rem;
          }
        `;
      case 'medium':
        return css`
          img,
          .image_container {
            height: 17.5rem;
            max-width: 359px;
          }
          h3 {
            font-size: 1.5rem;
            margin-top: 1.8rem;
          }

          p {
            display: none;
          }
        `;
      case 'normal':
        return css`
          img,
          .image_container {
            height: 11.3rem;
            max-width: 265px;
          }
          h3 {
            font-size: 1.12rem;
            font-weight: 500;
            margin-top: 2rem;
          }

          p {
            display: none;
          }
        `;
      default:
        return css``;
    }
  }}
`;
