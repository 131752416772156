import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin:0;
        padding:0;
        box-sizing:border-box;
        font-family:${({ theme }) => theme.fontFamily.primary}
    }

    html,body,#root,.App{
        height:100vh;
         scroll-behavior: smooth;
    }

    #root{
        display:flex;
        flex-direction:column;
    }
`;
