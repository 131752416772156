import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  align-items: center;
  flex-direction: column;

  footer {
    grid-column: span 12;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #dedede;
    border-bottom: 3px solid #5e5e5e;
    padding: 5rem 0;
    margin-bottom: 1px;
    margin-top: auto;

    p {
      color: ${({ theme }) => theme.text.primary};
    }

    > div {
      display: flex;
      align-items: center;

      > * {
        padding: 0 8px;
        box-sizing: content-box;
      }
    }
    .button-whatsapp {
      border: 1px solid #00e676;
      box-sizing: border-box;
      border-radius: 40px;
      display: flex;
      padding: 1px;
      align-items: center;
      text-decoration: none;
      cursor: pointer;

      > div {
        width: 2.3rem;
        height: 2.3rem;
        background: #00e676;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        font-size: 1.12rem;
        margin: 0 0.8rem;
        color: ${({ theme }) => theme.text.primary};
      }
    }
  }

  @media (max-width: 800px) {
    footer {
      display: flex;
      flex-direction: column-reverse;
      padding: 3rem 0;
      height: 17rem;
      column-gap: 2rem;
    }
  }
`;
