import Footer from 'components/Footer';
import Header from 'components/Header';
import { useContextProvider } from 'services/Context';

const PageLayout = ({ children }) => {
  const { headerBackground } = useContextProvider();

  return [
    <Header secondary={headerBackground === 'secondary'} key="header" />,
    children,
    <Footer />,
  ];
};
export default PageLayout;
