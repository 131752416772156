import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Article from 'views/Article';
import Blog from 'views/Blog';
import Home from 'views/Home';
import Links from 'views/Links';
import Tools from 'views/Tools';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/links" component={Links} />
      <Route exact path="/blog" component={Blog} />
      <Route exact path="/ferramentas" component={Tools} />
      <Route exact path="/:id" component={Article} />
    </Switch>
  </Router>
);

export default Routes;
