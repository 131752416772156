import { useQuery } from '@apollo/client';
import houseIcon from 'assets/house-icon.png';
import Main from 'components/Containers/Main';
import Header from 'components/Header';
import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { TOOLS } from 'services/graphql/queries';

import Container, { ContainerModal, ButtonIcon } from './styles';

const Tools = () => {
  const { data } = useQuery(TOOLS);
  const [tools, setTools] = useState([]);
  const [touch, setTouch] = useState({
    timeOut: null,
    tool: {},
  });
  const [show, setShow] = useState({
    show: false,
    data: {},
  });

  useEffect(() => {
    document.title = 'Ferramentas';
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }
    const newTools = data.tools.map(tool => {
      const screenshotsMap = tool.screenshots.map(screenshot => ({
        ...screenshot,
        urlComplete: `${process.env.REACT_APP_URL_IMAGE}${screenshot.url}`,
      }));
      return {
        ...tool,
        urlIconComplete: `${process.env.REACT_APP_URL_IMAGE}${tool.icon.url}`,
        screenshots: screenshotsMap,
      };
    });
    setTools(newTools);
  }, [data]);

  return (
    <>
      <ContainerModal show={show.show}>
        <button
          type="button"
          aria-label="clique para fechar"
          onClick={() => setShow(false)}
          className="modal"
        />
        <button
          type="button"
          aria-label="clique para fechar"
          onClick={() => setShow(false)}
          className="close"
        >
          <AiOutlineClose size={24} color="#fff" />
        </button>
        <article>
          <header>
            <img
              src={show.data?.urlIconComplete}
              alt={show.data?.alternativeText}
            />
          </header>
          <div>
            <h3>{show.data?.title}</h3>
            <p>{show.data?.description}</p>
            {show.data?.screenshots &&
              show.data.screenshots.map(screenshot => (
                <img
                  key={screenshot.id}
                  src={screenshot.urlComplete}
                  alt={screenshot.alternativeText}
                  className="span-3"
                />
              ))}
          </div>
        </article>
      </ContainerModal>
      <Container>
        <span className="block-larger" />
        <span className="block-small" />
        <Main>
          <Header />
          <div className="text_container">
            <div>
              <img src={houseIcon} alt="house" />
              <p> {'>'} Ferramentas</p>
            </div>
            <h1>FERRAMENTAS</h1>
            <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h2>
          </div>
          <div className="icons">
            {tools.map(tool => (
              <ButtonIcon
                onTouchEnd={() => {
                  clearTimeout(touch.timeOut);
                }}
                onTouchStart={() => {
                  setTouch({
                    timeOut: setTimeout(() => {
                      setShow({ show: true, data: tool });
                    }, 1000),
                    tool,
                  });
                }}
                key={tool.id}
                type="button"
                aria-label="4"
                icon={tool.urlIconComplete}
                onClick={() => {
                  window.location.href = tool.link;
                }}
                onMouseEnter={() => {
                  if (
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                      navigator.userAgent
                    )
                  ) {
                    return;
                  }
                  setShow({ show: true, data: tool });
                }}
                activeButton={show.data?.id === tool.id}
              >
                <img src={tool.urlIconComplete} alt={tool.alternativeText} />
                <p>{tool.title}</p>
              </ButtonIcon>
            ))}
          </div>
          {show.data?.title && (
            <>
              <div className="description">
                <h4>{show.data.title}</h4>
                <p>{show.data.description}</p>
              </div>
              <div className="images">
                {show.data.screenshots.map(screenshot => (
                  <img
                    key={screenshot.id}
                    src={screenshot.urlComplete}
                    alt={screenshot.alternativeText}
                    className="span-3"
                  />
                ))}
              </div>
            </>
          )}
        </Main>
      </Container>
    </>
  );
};

export default Tools;
