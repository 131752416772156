import styled from 'styled-components';

export default styled.div`
  width: 100%;
  max-width: 71.25rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2rem;
  flex: 1;

  @media (max-width: 1300px) {
    max-width: 50rem;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
`;
