import { createContext, useCallback, useContext, useState } from 'react';

const Context = createContext({
  headerBackground: '',
  updateToPrimary() {},
  updateToSecondary() {},
});

export default ({ children }) => {
  const [headerBackground, setHeaderBackground] = useState('primary');

  const updateToSecondary = useCallback(() => {
    setHeaderBackground('secondary');
  }, []);
  const updateToPrimary = useCallback(() => {
    setHeaderBackground('primary');
  }, []);

  return (
    <Context.Provider
      value={{ headerBackground, updateToPrimary, updateToSecondary }}
    >
      {children}
    </Context.Provider>
  );
};

export const useContextProvider = () => useContext(Context);
