import Main from 'components/Containers/Main';
import { FaWhatsapp, FaInstagram, FaLinkedin } from 'react-icons/fa';

import Container from './styles';

const Footer = () => (
  <Container>
    <Main>
      <footer>
        <p>Copyright © Filipe Lopes</p>
        <p>Medicina com Tecnologia</p>
        <div>
          <a
            href="https://www.linkedin.com/in/filipelopes-med-br/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={32} color="#0A66C2" aria-label="Linkedin" />
          </a>
          <a
            href="https://www.instagram.com/filipelopes.med.br/"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram size={32} color="#C32681" aria-label="Instagram" />
          </a>
          <a
            href="https://wa.me/5571992518950?text=Ol%C3%A1+Dr.+Filipe%2C+entrei+em+contato+pelo+seu+site%21"
            className="button-whatsapp"
          >
            <div>
              <FaWhatsapp size={24} color="#fff" />
            </div>
            <p>71 9 9251 8950</p>
          </a>
        </div>
      </footer>
    </Main>
  </Container>
);

export default Footer;
