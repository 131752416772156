import styled from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: stretch;

  .profile_photo {
    background-image: url(profile.jpeg);
    width: 96px;
    height: 96px;
    margin-bottom: 1rem;
    border-radius: 50%;
    background-position: center -4px;
    background-size: 125%;
  }

  .text_container {
    margin-top: 1rem;
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    align-items: center;

    /* Referência da página */
    > div {
      display: flex;
      align-items: center;

      img {
        margin-right: 0.5rem;
      }
    }

    #links_container {
      display: flex;
      align-items: stretch;
      flex-direction: column;
      flex-wrap: nowrap;
      margin-bottom: 2rem;
    }

    h2 {
      color: ${({ theme }) => theme.text.primary};
      font-size: 2.25rem;
      margin-bottom: 1rem;
      font-weight: 600;
      max-width: 46.9rem;
      text-align: center;
      margin-top: 7.5rem;
      font-family: ${({ theme }) => theme.fontFamily.primary};
    }

    h1 {
      color: #74ab82;
      font-size: 4.5rem;
      margin-bottom: 2rem;
      font-weight: 700;
      font-family: ${({ theme }) => theme.fontFamily.primary};
    }
  }

  @media (max-width: 800px) {
    .text_container {
      h1 {
        font-size: 2.25rem;
        margin-top: 0.5rem;
      }

      h2 {
        font-size: 1.5rem;
        margin-top: 1.5rem;
      }
    }
  }
`;
