import styled, { css } from 'styled-components';

export default styled.div`
  pointer-events: none;
  padding: 0.5rem 1rem;
  background: ${({ theme }) => theme.button.primary};
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 0.5rem;
  z-index: 4 !important;
  opacity: 0;
  transition: opacity 0.4s linear;
  ${({ show }) =>
    show &&
    css`
      opacity: 1;
    `}
`;
