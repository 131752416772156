/* eslint-disable react/style-prop-object */
import { useQuery } from '@apollo/client';
import ArticleCard from 'components/ArticleCard';
import Main from 'components/Containers/Main';
import Carousel from 'nuka-carousel';
import { useEffect, useState } from 'react';
import { useContextProvider } from 'services/Context';
import { POSTS } from 'services/graphql/queries';

import Container from './styles';

const Blog = () => {
  const { data } = useQuery(POSTS);
  const [posts, setPosts] = useState([]);
  const [lastPosts, setLastPosts] = useState([]);
  const { updateToPrimary } = useContextProvider();
  useEffect(() => {
    document.title = 'Blog';
    updateToPrimary();
  }, [updateToPrimary]);

  useEffect(() => {
    if (!data) {
      return;
    }
    const newPosts = data.posts.map(post => ({
      ...post,
      urlComplete: post?.cover?.url
        ? `${process.env.REACT_APP_URL_IMAGE}${post?.cover?.url}`
        : null,
    }));
    const newPostsFilter = newPosts.filter(post =>
      post.labels.find(label => label.name === 'root')
    );

    const newPostsLastFilter = newPostsFilter.filter((_, index) => index > 2);
    setLastPosts(newPostsLastFilter);
    setPosts(newPostsFilter);
  }, [data]);

  return (
    <Container>
      <Main className="header">
        <h2 className="title">BLOG</h2>

        <div className="card-highlight">
          {posts[0] && (
            <ArticleCard
              to={`/${posts[0].slug}`}
              title={posts[0].title}
              text={posts[0].content}
              url={posts[0].urlComplete}
            />
          )}
        </div>
        <div className="card-medium">
          {' '}
          {posts[1] && (
            <ArticleCard
              className="margin"
              to={`/${posts[1].slug}`}
              title={posts[1].title}
              text={posts[1].content}
              url={posts[1].urlComplete}
            />
          )}{' '}
          {posts[2] && (
            <ArticleCard
              to={`/${posts[2].slug}`}
              title={posts[2].title}
              text={posts[2].content}
              url={posts[2].urlComplete}
            />
          )}
        </div>
      </Main>
      <div className="most-views">
        <Main>
          <h2>Posts mais recentes </h2>
          <Carousel
            slidesToShow={4}
            style={{ gridColumn: 'span 12' }}
            cellSpacing={24}
            withoutControls
          >
            {lastPosts.map(post => (
              <div className="card-normal">
                <ArticleCard
                  className="margin"
                  to={`/${post.slug}`}
                  title={post.title}
                  text={post.content}
                  url={post.urlComplete}
                />
              </div>
            ))}
          </Carousel>
        </Main>
      </div>
      <Main className="card-big">
        <div>
          <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        </div>
        <img
          src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.b9vDv6DBBxfMX5lfG54eHQHaFj%26pid%3DApi&f=1"
          alt=""
        />
      </Main>
    </Container>
  );
};

export default Blog;
