import styled, { css } from 'styled-components';

export default styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: stretch;

  footer {
    margin-top: auto !important;
  }

  > div {
    z-index: 2;
    height: 100%;
  }

  .icons {
    grid-column: span 12;
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
  }

  .description {
    grid-column: span 12;
    max-width: 38rem;
    margin-bottom: 3rem;

    h4 {
      margin-bottom: 2rem;
      font-weight: 600;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.text.primary};
      font-family: ${({ theme }) => theme.fontFamily.primary};
    }

    p {
      font-weight: 500;
      font-size: 1.12rem;
      line-height: 29px;
      color: ${({ theme }) => theme.text.primary};
      font-family: ${({ theme }) => theme.fontFamily.primary};
    }
  }
  .images {
    grid-column: span 12;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4rem;
    img {
      object-fit: contain;
      margin-right: 1rem;
    }
  }

  .text_container {
    margin-top: 12rem;
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      align-items: center;

      p {
        font-family: ${({ theme }) => theme.fontFamily.primary};
        font-size: 1.2rem;
      }

      img {
        width: 14px;
        height: 15px;
        margin-right: 1rem;
      }
    }

    h2 {
      color: ${({ theme }) => theme.text.primary};
      font-size: 2.25rem;
      margin-bottom: 1rem;
      font-weight: 600;
      max-width: 46.9rem;
      text-align: center;
      margin-top: 7.5rem;
      font-family: ${({ theme }) => theme.fontFamily.primary};
    }

    h1 {
      color: #74ab82;
      font-size: 4.5rem;
      margin-bottom: 2rem;
      font-weight: 700;
      font-family: ${({ theme }) => theme.fontFamily.primary};
    }

    p {
      font-size: 1.12rem;
      color: ${({ theme }) => theme.text.primary};
      max-width: 23rem;
      font-family: ${({ theme }) => theme.fontFamily.primary};
    }
  }

  @media (max-width: 800px) {
    footer {
      margin-top: 4rem;
    }
    .icons {
      column-gap: 2rem;
      justify-content: center;
    }
    .description {
      display: none;
    }

    img {
      &.span-3 {
        display: none;
      }

      &.span-5 {
        display: none;
      }

      &.span-4 {
        display: none;
      }
    }
    .text_container {
      h1 {
        font-size: 2.25rem;
        margin-top: 2rem;
      }

      h2 {
        font-size: 1.5rem;
        margin-top: 1.5rem;
      }
    }
  }
`;

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  justify-content: center;
  z-index: 10;
  padding-top: 5rem;

  > .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: none;
    border: none;
    outline: none;
  }

  > .close {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  > article {
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    position: relative;
    border-radius: 0.5rem;

    > header {
      width: 5.62rem;
      height: 5.62rem;
      margin-top: -2.81rem;
      background: #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
      border-radius: 17.9999px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.5rem;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    > div {
      overflow: auto;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 1.2rem;
      padding-bottom: 1.2rem;
      padding-top: 3.5rem;

      h3 {
        color: ${({ theme }) => theme.text.primary};
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      p {
        color: ${({ theme }) => theme.text.primary};
        font-size: 1.12rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 2rem;
      }

      img {
        width: 100%;
        border-radius: 0.5rem;
        object-fit: contain;
        margin-bottom: 0.5rem;
      }
    }
  }

  @media (max-width: 800px) {
    display: ${({ show }) => (show ? 'flex' : 'none')};
  }
`;

export const ButtonIcon = styled.button`
  width: 5rem;
  height: 5rem;
  margin-right: 3.31rem;
  padding: 0.5rem;
  margin-bottom: 4rem;
  border: none;
  outline: none;
  cursor: pointer;
  background: #eeeeee;
  border-radius: 1rem;
  position: relative;
  transition: background 0.2s linear, width 0.2s linear, height 0.2s linear,
    margin-right 0.2s linear, margin-bottom 0.2s linear, box-shadow 0.2s linear;

  p {
    position: absolute;
    bottom: -2rem;
    width: 100%;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s linear;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }
  ${({ activeButton }) =>
    activeButton &&
    css`
      background: #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
      width: 5.6rem;
      height: 5.6rem;
      margin-right: 2.72rem;
      margin-bottom: 2rem;

      p {
        opacity: 1;
      }
    `}

  :hover {
    background: #ffffff;
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
    width: 5.6rem;
    height: 5.6rem;
    margin-right: 2.72rem;
    margin-bottom: 2rem;

    p {
      opacity: 1;
    }
  }

  @media (max-width: 800px) {
    margin-right: 0;

    :hover {
      background: #ffffff;
      box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);
      width: 5rem;
      height: 5rem;
      margin-right: 0;
      margin-bottom: 4rem;
    }
  }
`;
