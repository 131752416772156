import PropTypes from 'prop-types';

import Container from './styles';

const CopySucess = ({ show, text }) => (
  <Container show={show}>{text}</Container>
);

CopySucess.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
};
CopySucess.defaultProps = {
  show: false,
  text: '',
};

export default CopySucess;
