import blockBackground from 'assets/blockBackground.png';
import blockBackgroundSecond from 'assets/blockBackgroundSecond.png';
import styled, { css, keyframes } from 'styled-components';

const loadingAnimation = keyframes`
    from{
        transform:rotate(0deg)
    }to{
        transform:rotate(360deg)

    }
`;

export default styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;

  > div {
    z-index: 2;
  }

  .zIndex {
    z-index: 3;
  }

  .text_tecnolog {
    margin-top: 14rem;
    margin-bottom: 4.5rem;
    grid-column: span 8;

    h2 {
      font-size: 4.5rem;
      color: ${({ theme }) => theme.text.primary};
      font-weight: 700;
    }
  }

  .full-container {
    width: 100%;
    height: 50rem;

    img {
      object-fit: cover;
      width: 100%;
      height: 50rem;
    }
  }

  .top {
    background: #fff;
    margin-top: -13.75rem;
  }

  .footer-social-redes {
    grid-column-start: 5;
    grid-column-end: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 2.25rem;
      color: ${({ theme }) => theme.text.primary};
      margin-bottom: 1.2rem;
      text-align: center;
      font-weight: 600;
    }

    > div {
      display: flex;
      justify-content: space-between;
      padding-top: 1.2rem;
      max-width: 11.37rem;
      width: 100%;
    }
  }

  .footer-form {
    grid-column-start: 2;
    grid-column-end: 12;
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 2rem;
    padding-top: 7.75rem;
    padding-bottom: 8rem;

    .input {
      grid-column: span 5;
    }

    .textarea {
      grid-column: span 10;
      margin-top: 1rem;
    }

    button {
      margin-top: 1.5rem;
      grid-column-start: 5;
      grid-column-end: 7;
      font-weight: 600;
      letter-spacing: 0.3em;
      text-transform: uppercase;
      .loading {
        animation: ${loadingAnimation} infinite 1s linear;
      }
    }
  }

  @media (max-width: 1300px) {
    .text_tecnolog {
      grid-column: span 6;

      h2 {
        font-size: 2.5rem;
      }
    }

    .social_redes_container {
      grid-column: span 6;
    }

    .footer-social-redes {
      grid-column-start: 4;
      grid-column-end: 10;
    }

    .footer-form {
      button {
        grid-column-start: 4;
        grid-column-end: 8;
      }
    }
  }
  @media (max-width: 800px) {
    .full-container {
      height: 17.5rem;

      img {
        height: 17.5rem;
      }
    }

    .top {
      margin-top: 2rem;
    }

    .text_tecnolog {
      margin-top: 2.8rem;
      margin-bottom: 2rem;
      h2 {
        font-size: 2rem;
      }
    }

    .social_redes_container {
      display: none;

      &.mobile {
        display: flex;
      }
    }

    .footer-form {
      display: flex;
      flex-direction: column;
      align-items: center;

      .input,
      .textarea {
        margin-top: 0.5rem;
      }

      button {
        max-width: 10rem;
      }
    }
  }
`;

export const SocialRedesContainer = styled.div`
  margin-top: 15.5rem;
  padding-top: 2.5rem;
  grid-column: span 4;
  border-top: 3px solid #74ab82;
  display: flex;
  justify-content: flex-end;

  button {
    border: none;
    cursor: pointer !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    background: #5e5e5e !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 100% !important;
    margin-right: 0.8rem !important;
  }

  @media (max-width: 1300px) {
    grid-column: span 6 !important;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const SocialRedesContainerMobile = styled(SocialRedesContainer)`
  width: 100%;
  display: none;
  margin: 1rem 0;
  justify-content: center;

  button {
    width: 1.5rem !important;
    height: 1.5rem !important;

    img {
      width: 0.75rem;
    }
  }

  @media (max-width: 800px) {
    display: flex;
  }
`;

export const TextContainer = styled.div`
  margin-top: 12rem;
  grid-column: span 5;

  h2 {
    color: ${({ theme }) => theme.text.primary};
    font-size: 3rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  h1 {
    color: ${({ theme }) => theme.text.primary};
    font-size: 4.5rem;
    margin-bottom: 2rem;
    font-weight: 700;
  }

  p {
    font-size: 1.12rem;
    color: ${({ theme }) => theme.text.primary};
    max-width: 23rem;
  }

  a {
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    height: 3rem;
    background: #fff;
    border: 1px solid #c4c4c4;
    font-size: 1.12rem;
    width: 12.25rem;
    margin-top: 4rem;
    transition: background 0.2s linear;

    p {
      margin-left: 1rem;
      color: ${({ theme }) => theme.text.primary};
      transition: color 0.2s linear;
    }
    svg {
      transition: color 0.2s linear;
    }

    :hover {
      background: #74ab82;

      p {
        color: #fff;
      }
      svg {
        color: ${({ theme }) => theme.text.secondary} !important;
      }
    }
  }

  @media (max-width: 1300px) {
    margin-bottom: 2rem;

    h2 {
      font-size: 2rem;
    }

    h1 {
      font-size: 3rem;
    }
  }

  @media (max-width: 800px) {
    margin-top: 32rem;
    background: #fff;
    z-index: 2;
    padding-top: 1rem;
    padding-right: 1rem;

    h1 {
      font-size: 2.8rem;
    }
  }
`;

export const Block = styled.div`
  height: 27.5rem;
  grid-column: span 4;

  a {
    border-bottom: 4px solid #dedede;
    background-size: cover;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-image: url(${blockBackground});
    text-decoration: none;
    height: 21.25rem;
    transition: height 0.2s linear, background-image 0.2s linear;
    z-index: 2;
    .alert {
      opacity: 0;
      position: absolute;
      height: 2rem;
      background: #fff;
      border-radius: 0.5rem;
      top: -2.5rem;
      left: 0;
      padding: 0.5rem 1rem;
      width: max-content;
      color: #5e5e5e;
      transition: opacity 0.2s linear;
    }

    span {
      position: absolute;
      bottom: 2.5rem;
      right: 1.5rem;
      opacity: 0;
      transition: opacity 0.2s linear;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        filter: grayscale(100%);
        cursor: auto;
      `}

    > div {
      position: relative;
      width: 4.19rem;

      img {
        position: absolute;
        object-fit: contain;
        transition: opacity 0.2s linear;
        width: inherit;

        :nth-child(2) {
          opacity: 0;
        }
      }
    }

    p {
      font-size: 2.5rem;
      color: ${({ theme }) => theme.text.primary};
      font-weight: 600;
      text-decoration: none;
    }

    @media (max-width: 1300px) {
      p {
        font-size: 1.5rem;
      }
    }

    :hover {
      height: 27.5rem;
      border-bottom: 4px solid rgba(0, 0, 0, 0.5);

      span {
        opacity: 0.5;
      }

      > div {
        img {
          position: absolute;
          object-fit: contain;
          transition: opacity 0.2s linear;
          opacity: 0;
          :nth-child(2) {
            opacity: 1;
          }
        }
      }

      .alert {
        opacity: 1;
      }
      ${({ disabled }) =>
        !disabled
          ? css`
              background-image: url(${blockBackgroundSecond});

              p {
                color: #000;
              }
            `
          : css`
              filter: grayscale(100%);
              p {
                color: rgba(0, 0, 0, 0.5);
              }
            `}
    }
  }

  @media (max-width: 800px) {
    height: auto;
    a {
      span {
        opacity: 0;
      }
      flex-direction: row;
      padding-left: 1rem;
      padding-right: 1rem;
      border: 0px solid #fff;
      border-right: 4px solid #dedede;

      height: auto;
      > div {
        display: flex;
        align-items: center;
        width: 2.5rem;
        margin-right: 2.37rem;
      }

      h3 {
        font-size: 1.5rem;
      }
      :hover {
        span {
          opacity: 0;
        }
        height: auto;
        border-right: 4px solid rgba(0, 0, 0, 0.5);
        border-bottom: none;
      }
    }
  }
`;

export const ButtonCarrousel = styled.button`
  width: 5rem;
  height: 13.75rem;
  border: none;
  margin-bottom: 0.85rem;
  cursor: pointer;
  margin-bottom: -0.5rem;
  position: relative;
  background: rgba(0, 0, 0, 0);

  > span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: #060606;
    transition: background 0.2s linear;
    z-index: -1;
  }

  &.left {
    margin-left: -5rem;
  }
  &.right {
    margin-right: -5rem;
  }

  :hover {
    > span {
      background: #74ab82;
    }
    :focus {
      > span {
        background: #50af47;
      }
    }
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const ContainerComment = styled.div`
  grid-column: span 12;

  .slider-list {
    cursor: default !important;
  }

  .slider-control-bottomcenter {
    top: -1.2rem;
    bottom: none;
  }

  .dot li {
    margin-right: 0.75rem;

    button {
      background: #fff !important;
      opacity: 1 !important;
      width: 1rem;
      height: 1rem;
      border-radius: 100%;
      box-shadow: 0px 7.12246px 7.12246px rgba(0, 0, 0, 0.25);
      svg {
        display: none;
      }
    }
    &.active {
      button {
        background: #74ab82 !important;
      }
    }
  }
  .comment {
    width: 100%;
    padding: 0 5.3rem;
    padding-bottom: 1.5rem;
    padding-top: 3rem;

    p {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.text.primary};

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      -webkit-box-orient: vertical;
      strong {
        font-weight: 500;
        font-style: italic;
      }
    }

    .profile-container {
      margin-top: 1.3rem;
      display: flex;
      align-items: center;

      img {
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        margin-right: 1rem;
        object-fit: cover;
      }

      p {
        font-size: 0.8rem;
      }
    }
  }

  @media (max-width: 800px) {
    .comment {
      padding: 0 1.3rem;
    }
    .dot li {
      button {
        width: 0.75rem;
        height: 0.75rem;
        box-shadow: none;
        border: 1px solid #5e5e5e !important;
      }
      &.active {
        button {
          border: none !important;
        }
      }
    }
  }
`;

export const ImageContainer = styled.div`
  margin-top: 12rem;
  position: relative;
  padding-left: 1rem;
  grid-column-start: 7;
  overflow: hidden;
  grid-column-end: 13;
  height: 45.3rem;
  display: flex;
  flex-direction: column;
  z-index: 1;

  img {
    position: absolute;
    bottom: -75px;
    right: 0.5rem;
    z-index: 2;
  }

  > div {
    display: flex;

    :last-child {
      flex: 1;

      > div {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        span {
          flex: 0.5;
          height: 5.62rem;
          background: #db4444;
          opacity: 0;
          transition: opacity 0.2s linear;
        }
      }

      .larger_background {
        flex: 2;
        background: #77cc90;
        align-self: stretch;
        height: auto;
        transition: opacity 0.2s linear;
      }
    }

    span {
      display: block;
      flex: 1;
      height: 11.3rem;
      transition: background 0.2s linear, opacity 0.2s linear;

      :nth-child(1) {
        background: #e6e6e6;
      }

      :nth-child(2) {
        background: #7bf099;
      }

      :nth-child(3) {
        background: #db4444;
      }
    }
  }

  :hover {
    > div {
      :last-child {
        > div {
          span {
            opacity: 1;
          }
        }

        .larger_background {
          opacity: 0;
        }
      }

      span {
        :nth-child(1) {
          opacity: 0;
        }

        :nth-child(2) {
          background: #7bf099;
        }

        :nth-child(3) {
          background: #77cc90;
        }
      }
    }
  }

  @media (max-width: 800px) {
    position: absolute;
    top: 8rem;
    left: 0;
    width: 100%;
    height: 35.1rem;
    margin-top: 0;

    img {
      bottom: 0;
      max-width: 16.3rem;
    }
    > div span {
      height: 7.8rem;
    }
  }
`;
