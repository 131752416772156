import { useQuery } from '@apollo/client';
import houseIcon from 'assets/house-icon.png';
import Main from 'components/Containers/Main';
import Footer from 'components/Footer';
import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { LINKS } from 'services/graphql/queries';

import LinkItem from './components/LinkItem';
import Container from './styles';

const Tools = () => {
  //  get links to populate list
  const { data, loading } = useQuery(LINKS);

  useEffect(() => {
    document.title = 'Filipe Lopes - Meus Links';
  }, []);

  return [
    <Container key="content">
      <Main>
        <div className="text_container">
          <div className="profile_photo" />
          <div>
            <Link to="/">
              <img src={houseIcon} alt="house" />
            </Link>
            <p> {'>'} Meus Links</p>
          </div>
          <h1>LINKS</h1>
          <div id="links_container">
            {loading
              ? 'Carregando links...'
              : data?.links?.map(link => (
                  <LinkItem
                    key={link.id}
                    url={link.url}
                    description={
                      <ReactMarkdown>{link.description}</ReactMarkdown>
                    }
                    disabled={link.disabled}
                  />
                ))}
          </div>
        </div>
      </Main>
    </Container>,
    <Footer key="footer" />,
  ];
};

export default Tools;
