import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
    from{
        transform:rotate(0deg)
    }to{
        transform:rotate(360deg)

    }
`;

export default styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: #fafafa;
  flex: 1;
  justify-content: center;

  .minHeight {
    min-height: 50rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .loading {
    svg {
      animation: ${loadingAnimation} infinite 1s linear;
    }
  }

  .card-normal {
    grid-column: span 3;
  }

  .most-views {
    width: 100%;
    background: #f3f3f3;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 7rem;
    z-index: 1;

    span {
      grid-column: span 12;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h2 {
      grid-column: span 12;
      font-weight: 600;
      font-size: 2.25rem;
      color: #3e3f3d;
      margin-top: 6rem;
      margin-bottom: 3.4rem;
    }
  }

  .header-article {
    margin-top: 12rem;
    grid-column-start: 2;
    grid-column-end: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-weight: 600;
      color: ${({ theme }) => theme.text.primary};
      font-size: 2.25rem;
    }

    > div {
      align-self: stretch;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .social_redes_container {
        display: flex;

        button {
          cursor: pointer !important;
          border: none;
          width: 1.5rem !important;
          height: 1.5rem !important;
          background: #5e5e5e !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          border-radius: 100% !important;
          margin-right: 0.8rem !important;
        }

        img {
          width: 12px;
          object-fit: contain;
        }
      }
    }
  }

  .content-article {
    grid-column-start: 3;
    grid-column-end: 11;
    margin-top: 2rem;
    margin-bottom: 7rem;
    z-index: 0;

    p {
      font-size: 1.12rem;
      color: ${({ theme }) => theme.text.primary};
      margin-bottom: 2.25rem;
    }

    img {
      width: 100%;
      height: 15rem;
      object-fit: cover;
      margin-bottom: 1.5rem;
    }

    h3 {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
      color: ${({ theme }) => theme.text.primary};
      font-weight: 600;
      text-align: center;
    }
  }

  .full-container {
    width: 100%;
    height: 50rem;
    z-index: 1;

    img {
      width: 100%;
      height: 50rem;
      object-fit: cover;
    }
  }

  .profile-container {
    margin-top: 1.3rem;
    display: flex;
    align-items: center;

    img {
      width: 3rem;
      height: 3rem;
      border-radius: 100%;
      margin-right: 1rem;
      object-fit: cover;
    }

    p {
      font-size: 0.8rem;
      color: ${({ theme }) => theme.text.primary};
    }
  }
`;
