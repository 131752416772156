import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import useDimension from 'services/hooks/useDimension';

import Container from './styles';

const ArticleCard = ({ title, text, url, ...rest }) => {
  const ref = useRef(null);
  const { width } = useDimension(ref);
  const [sizeType, setSizeType] = useState('larger');

  useEffect(() => {
    let newSizeType = 'larger';

    if (width <= 749) {
      newSizeType = 'larger';
    }

    if (width <= 359) {
      newSizeType = 'medium';
    }

    if (width <= 265) {
      newSizeType = 'normal';
    }

    setSizeType(newSizeType);
  }, [width]);

  return (
    <Container ref={ref} sizetype={sizeType} {...rest}>
      {url ? (
        <img
          src={
            url ||
            'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.s6XJW4oxNuygw7C4UBnZggHaEK%26pid%3DApi&f=1'
          }
          alt="imagem"
        />
      ) : (
        <div className="image_container" />
      )}
      <h3>{title}</h3>
      <p>{text}</p>
    </Container>
  );
};

ArticleCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default ArticleCard;
