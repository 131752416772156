export default {
  text: {
    primary: '#5E5E5E',
    secondary: '#DB4444',
  },
  button: {
    primary: '#74AB82',
  },
  fontFamily: {
    primary: 'Montserrat',
  },
};
