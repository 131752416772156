/* eslint-disable no-shadow */
import { useQuery } from '@apollo/client';
import cameraIcon from 'assets/cameraWhite.svg';
import ArticleCard from 'components/ArticleCard';
import Main from 'components/Containers/Main';
import CopySucess from 'components/CopySucess';
import PageLayout from 'components/PageLayout';
import { format, parseISO } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import Carousel from 'nuka-carousel';
import { useCallback, useEffect, useState } from 'react';
import { AiOutlineLink, AiOutlineLoading } from 'react-icons/ai';
import { ImLinkedin2, ImFacebook, ImTwitter } from 'react-icons/im';
import { useParams } from 'react-router-dom';
import { useContextProvider } from 'services/Context';
import { GET_POST_BY_SLUG, POSTS } from 'services/graphql/queries';

import Container from './styles';

const Article = () => {
  const params = useParams();
  const { data } = useQuery(GET_POST_BY_SLUG, {
    variables: {
      slug: params.id,
    },
  });
  const { data: dataPosts } = useQuery(POSTS, { limit: 4 });
  const [loadingPost, setLoadingPost] = useState(true);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState({});
  const { updateToSecondary } = useContextProvider();
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  function verifyIfImageExists(url) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', err => reject(err));
      image.src = url;
    });
  }

  const createArticleHtml = useCallback(
    (textArray, index = 0, articleArray = []) => {
      async function functionarticle(textArray, index = 0, articleArray = []) {
        const regexImage = new RegExp(/(!\[+.+\])(\(+.+\))/gi);
        const paragraph = textArray[index];
        if (paragraph === undefined) {
          return articleArray.join('');
        }
        if (paragraph[0] === '#' && paragraph[1] === '#') {
          articleArray.push(
            `<h3>${paragraph.slice(2, paragraph.length).trim()}</h3>`
          );
          return functionarticle(textArray, index + 1, articleArray);
        }
        if (paragraph.match(regexImage)) {
          const data = paragraph.replace(regexImage, '$1+$2').split('+');
          const url = data[1].trim().slice(1, -1);
          try {
            const fullUrl = `${process.env.REACT_APP_URL_IMAGE}${url}`;
            await verifyIfImageExists(fullUrl);
            const alternativeText = data[0].trim().slice(2, -1);
            if (articleArray[articleArray.length - 1].trim() === '</div>') {
              articleArray.splice(
                articleArray.length - 1,
                0,
                `<img src=${fullUrl} alt=${alternativeText} />`
              );

              return functionarticle(textArray, index + 1, articleArray);
            }
            articleArray.push('<div class="images">');
            articleArray.push(`<img src=${fullUrl} alt=${alternativeText} />`);
            articleArray.push('</div>');
            return functionarticle(textArray, index + 1, articleArray);
          } catch {
            if (articleArray[articleArray.length - 1].trim() === '</div>') {
              articleArray.splice(
                articleArray.length - 1,
                0,
                '<div class="errorImage">Sem Imagem</div>'
              );

              return functionarticle(textArray, index + 1, articleArray);
            }
            articleArray.push('<div class="images">');
            articleArray.push('<div  class="errorImage"  >Sem Imagem</div>');
            articleArray.push('</div>');
            return functionarticle(textArray, index + 1, articleArray);
          }
        }
        articleArray.push(`<p>${paragraph.trim()}</p>`);
        return functionarticle(textArray, index + 1, articleArray);
      }
      return functionarticle(textArray, index, articleArray);
    },
    []
  );

  useEffect(() => {
    document.title = 'Artigo';
    updateToSecondary();
  }, [updateToSecondary]);

  useEffect(() => {
    if (!dataPosts || !post?.id) {
      return;
    }
    const newPosts = dataPosts.posts.map(post => ({
      ...post,
      urlComplete: post?.cover?.url
        ? `${process.env.REACT_APP_URL_IMAGE}${post.cover.url}`
        : null,
    }));
    const newPostsFilter = newPosts.filter(
      currentPost =>
        currentPost.labels.find(label => label.name === 'root') &&
        currentPost.id !== post.id
    );

    setPosts(newPostsFilter);
    setLoadingPosts(false);
  }, [dataPosts, post]);

  useEffect(() => {
    if (!data) {
      return;
    }

    async function createPost() {
      const newPost = {
        ...data.postBySlug,
        urlComplete: data?.postBySlug?.cover?.url
          ? `${process.env.REACT_APP_URL_IMAGE}${data.postBySlug.cover.url}`
          : null,
      };
      const dateFormat = format(
        parseISO(newPost.published_at),
        "dd 'de' MMMM - yyyy",
        {
          locale: ptBR,
        }
      );
      const urlWriteComplete = `${process.env.REACT_APP_URL_IMAGE}${newPost.writer.profile.url}`;

      const html = await createArticleHtml(newPost.content.split('\n'));
      setPost({
        ...newPost,
        html,
        dateFormat,
        writer: {
          ...newPost.writer,
          profile: { ...newPost.writer.profile, url: urlWriteComplete },
        },
      });
      document.title = newPost.title;
      setLoadingPost(false);
    }
    createPost();
  }, [data]);

  return (
    <PageLayout>
      <Container>
        {!loadingPost ? (
          <>
            <CopySucess show={showCopyMessage} text="Link Copiado" />
            <Main>
              <div className="header-article">
                <h2>{post.title}</h2>
                <div>
                  <div className="profile-container">
                    <img
                      src={post.writer.profile.url}
                      alt={post.writer.profile.alternativeText}
                    />
                    <p>
                      {post?.dateFormat || ''},{' '}
                      <strong>{`por ${post?.writer?.name || ''}.`}</strong>
                    </p>
                  </div>
                  <div className="social_redes_container">
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          navigator.canShare &&
                          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                          )
                        ) {
                          navigator.share({
                            text: `${process.env.REACT_APP_URL_FRONT}/${post.title}`,
                          });
                        } else {
                          const url = window.encodeURI(
                            `${process.env.REACT_APP_URL_FRONT}/${post?.slug}`
                          );
                          window.open(
                            `https://www.facebook.com/sharer.php?u=${url}`
                          );
                        }
                      }}
                      aria-label="link para o facebook"
                    >
                      <ImFacebook color="#fff" size={12} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          navigator.canShare &&
                          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                          )
                        ) {
                          navigator.share({
                            text: `${process.env.REACT_APP_URL_FRONT}/${post.title}`,
                          });
                        } else {
                          const url = window.encodeURI(
                            `${process.env.REACT_APP_URL_FRONT}/${post?.slug}`
                          );
                          window.open(
                            `https://www.linkedin.com/shareArticle/?url=${url}`
                          );
                        }
                      }}
                      aria-label="link para o linkedin"
                    >
                      <ImLinkedin2 color="#fff" size={12} />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          navigator.canShare &&
                          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                            navigator.userAgent
                          )
                        ) {
                          navigator.share({
                            text: `${process.env.REACT_APP_URL_FRONT}/${post.title}`,
                          });
                        } else {
                          const url = window.encodeURI(
                            `${process.env.REACT_APP_URL_FRONT}/${post?.slug}`
                          );
                          window.open(`https://twitter.com/share?url=${url}`);
                        }
                      }}
                      aria-label="link para o twitter"
                    >
                      <ImTwitter color="#fff" size={12} />
                    </button>
                    <button
                      onClick={() => {
                        if (navigator.canShare) {
                          navigator.share({
                            text: `http://localhost:3000/${post.slug}`,
                          });
                        }
                      }}
                      type="button"
                      aria-label="link para o instagram"
                    >
                      <img src={cameraIcon} alt="instagram" />
                    </button>
                    <button
                      type="button"
                      aria-label="copiar link"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(
                            `${process.env.REACT_APP_URL_FRONT}/${post.slug}`
                          )
                          .then(() => {
                            setShowCopyMessage(true);
                            setTimeout(() => {
                              setShowCopyMessage(false);
                            }, 1000);
                          });
                      }}
                    >
                      <AiOutlineLink color="#fff" size={12} />
                    </button>
                  </div>
                </div>
              </div>
            </Main>
            {post?.urlComplete !== null && (
              <div className="full-container">
                <img src={post?.urlComplete} alt="test" />
              </div>
            )}
            <Main>
              <article
                className="content-article"
                dangerouslySetInnerHTML={{
                  __html: post?.html,
                }}
              />
            </Main>
          </>
        ) : (
          <div className="minHeight">
            <span className="loading">
              <AiOutlineLoading size={45} color="#77CC90" />
            </span>
          </div>
        )}
        <div className="most-views">
          <Main>
            <h2>Posts mais recentes </h2>
            {!loadingPosts ? (
              <Carousel
                slidesToShow={4}
                style={{ gridColumn: 'span 12' }}
                cellSpacing={24}
                withoutControls
              >
                {posts.map(currentPost => (
                  <div key={currentPost.id} className="card-normal">
                    <ArticleCard
                      className="margin"
                      to={`/${currentPost.slug}`}
                      title={currentPost.title}
                      text={currentPost.content}
                      url={currentPost.urlComplete}
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <span className="loading">
                <AiOutlineLoading size={45} color="#77CC90" />
              </span>
            )}
          </Main>
        </div>
      </Container>
    </PageLayout>
  );
};

export default Article;
